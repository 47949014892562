<template >
  <div>
    <div class="card">
      <b-tabs v-model="toolbox_tab" align="left" content-class="mt-1 ml-1">
        <b-tab>
          <template #title>
            <feather-icon icon="ListIcon"></feather-icon>
            Liste des projets
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ActivityIcon"></feather-icon>
            Gestion des projets
          </template>
        </b-tab>
        <b-tab v-if="userInfo.role_id == 1">
          <template #title>
            <feather-icon icon="FileIcon"></feather-icon>
            Demande de Projets
          </template>
          <p>Liste de demandes des clients</p>
        </b-tab>
      </b-tabs>
    </div>
    <p id="flat" style="position: absolute"></p>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="toolbox_tab == 0">
        <all-enterprise-projects />
      </div>
      <b-card v-if="toolbox_tab == 1">
        <b-tabs end nav-class="tab-nav-class" v-model="tab">
          <b-tab
            :title="
              capitalize(localeDay(i + 1) + ' ' + (i + 1) + ' ' + activeMonth)
            "
            v-for="(item, i) in projectsBag"
            :key="i"
            :id="'tab' + i"
          >
            <div @contextmenu.prevent="$refs.menu.open">
              <b-table
                :ref="'sheet' + tab"
                @row-dblclicked="doubleClick"
                @row-clicked="clickRow"
                @row-contextmenu="rowContext"
                selected-variant="primary"
                bordered
                selectable
                responsive
                select-mode="single"
                :fields="fields"
                :items="projectsBag.get(item[0])"
                head-variant="dark"
                thead-class="thead-class"
                tbody-class="popo"
                sticky-header
                v-if="tab == i"
                :key="tableId"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th>
                      <feather-icon
                        v-if="localBackup.size == 0"
                        @click="flat.toggle()"
                        icon="CalendarIcon"
                        data-toggle
                      >
                      </feather-icon>
                    </b-th>
                    <b-th colspan="3" variant="secondary">
                      Liste des projets du mois de {{ activeMonth }}
                      {{ activeYear }}
                      <br />
                      <small>{{ backup_text }}(Ctrl+ S)</small>
                    </b-th>
                    <b-th variant="primary" class="text-center" colspan="1"
                      >{{ checkedCount(projectsBag.get(item[0])) }}/{{
                        checkedCountTotal(projectsBag.get(item[0]))
                      }}
                      {{
                        projectsBag.get(item[0]).length > 1
                          ? "Terminées"
                          : "Terminée"
                      }}</b-th
                    >

                    <b-th
                      style="cursor: pointer"
                      variant="danger"
                      class="text-center"
                      title="Synchroniser"
                      @click="syncronized = !syncronized"
                    >
                      <feather-icon
                        id="projects-comment"
                        size="20"
                        icon="RefreshCwIcon"
                      ></feather-icon>
                    </b-th>
                  </b-tr>
                </template>

                <template #cell(id)="data">
                  <span :id="'index-td' + data.index">{{
                    data.index + 1
                  }}</span>
                </template>

                <template #cell(is_finished)="data">
                  <b-form-checkbox
                    v-model="data.item.is_finished"
                    :value="1"
                    class="custom-control-primary"
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(name)="data">
                  <a
                    class="mt-1"
                    :id="'title' + data.index"
                    :style="data.item.is_finished ? line_through : ''"
                  >
                    {{ data.value || "- - - - - -" }}
                  </a>

                  <b-popover
                    placement="bottomright"
                    :target="'title' + data.index"
                    triggers="hover"
                    variant="dark"
                  >
                    <template #title>
                      Titre
                      <feather-icon
                        class="ml-auto close text-white"
                        size="20"
                        icon="XSquareIcon"
                      ></feather-icon>
                    </template>
                    <div>
                      <b-form-textarea
                        placeholder="Saisir le titre"
                        v-model="data.item.name"
                      >
                      </b-form-textarea>
                    </div>
                  </b-popover>
                </template>
                <template #cell(masters)="data">
                  <a
                    :id="'master' + data.index"
                    :style="data.item.is_finished ? line_through : ''"
                  >
                    {{ getEmployee(data.item.user_id) || "----" }}
                  </a>
                  <b-popover
                    :target="'master' + data.index"
                    title="Choisissez un responsable"
                    triggers="hover"
                  >
                    <div class="popover-div">
                      <a
                        class="ml-1"
                        @click="addCollaborator"
                        v-if="filtered_masters.length == 0"
                        >Ajouter un collaborateur</a
                      >
                      <div class="m-1">
                        <b-form-input
                          v-model="master_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(master, l) in filtered_masters" :key="l">
                        <b-link
                          @click="chooseMaster(item[0], data.index, master)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.user_id == master.id
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1"
                            >{{ master.firstname + " " + master.lastname }}
                          </span>
                        </b-link>
                        <hr />
                      </div>
                    </div>
                  </b-popover>
                </template>

                <template #cell(end_date)="data">
                  <div
                    class="d-flex align-items-center"
                    :style="data.item.is_finished ? line_through : ''"
                  >
                    <b-input
                      v-model="data.item.end_date"
                      class="form-control"
                      type="date"
                    ></b-input>
                  </div>
                </template>

                <template #cell(customer)="data">
                  <a
                    :id="'customer' + data.index"
                    class="d-flex align-items-center"
                    :style="data.item.is_finished ? line_through : ''"
                  >
                    {{ getCustomer(data.item.customer_id) || "-----" }}
                  </a>

                  <b-popover
                    :target="'customer' + data.index"
                    title="Choisissez un client"
                    triggers="hover"
                    v-if="data.item.project == null"
                  >
                    <div class="popover-div">
                      <div class="m-1">
                        <b-form-input
                          v-model="customer_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(customer, l) in filtered_customers" :key="l">
                        <b-link
                          @click="chooseCustomer(item[0], data.index, customer)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.customer_id != null &&
                              data.item.customer_id == customer.id
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1">{{ customer.name }} </span>
                        </b-link>
                        <hr />
                      </div>
                    </div>
                  </b-popover>
                </template>
              </b-table>
            </div>
            <b-row>
              <b-col cols="auto">Légende :</b-col>
              <b-col>
                <div class="legende-box-danger"></div>
                <p class="ml-3">Projet en rétard</p>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card v-if="toolbox_tab == 2" title="Projets demandés">
        <b-table
          :busy="isBusy"
          sticky-header
          style="height: 400px"
          no-border-collapse
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="allCustomersProjects"
          :fields="projectsItems"
          show-empty
          empty-text="Pas de projets disponible"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(action)="data">
            <b-avatar
              button
              @click="
                (projectDetailsSideBar = true),
                  (project = data.item),
                  (changeMasterBool = true)
              "
            >
              <feather-icon :icon="'EyeIcon'" />
            </b-avatar>
          </template>

          <!-- <template #cell(note)="data">
            <b-form-rating disabled :value="data.item.note" no-border class="text-warning"></b-form-rating>
          </template> -->
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Affichage par page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="this.filteredItems.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>

    <vue-context ref="menu">
      <li>
        <b-link @click="addLine" class="d-flex align-items-center">
          <feather-icon icon="PlusSquareIcon" size="16" />
          <span class="ml-75">Ajouter une ligne en dessous </span>
        </b-link>
        <hr />
      </li>

      <li v-if="selectedProject">
        <b-link @click="removeLine" class="d-flex align-items-center">
          <feather-icon icon="DeleteIcon" size="16" />
          <span class="ml-75"
            >Supprimer <b>{{ selectedProject.title }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
      <li v-if="selectedProject">
        <b-link
          @click="goToTask(selectedProject.id)"
          class="d-flex align-items-center"
        >
          <feather-icon icon="EyeIcon" size="16" />
          <span class="ml-75">Voir les tâches</span>
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
      </li>

      <li v-if="selectedProject && selectedProject.project">
        <b-link @click="detachProject()" class="d-flex align-items-center">
          <feather-icon icon="GitMergeIcon" size="16" />
          <span class="ml-75"
            >Détacher du projet <b>{{ selectedProject.project.name }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
      <li v-if="isCustomerDetachable() && selectedProject.customer">
        <b-link @click="detachCustomer()" class="d-flex align-items-center">
          <feather-icon icon="GitMergeIcon" size="16" />
          <span class="ml-75"
            >Détacher du client <b>{{ selectedProject.customer.name }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
    </vue-context>
    <b-sidebar
      no-close-on-backdrop
      ref="mySideBar"
      bg-variant="white"
      no-header-close
      backdrop
      shadow
      right
      width="850px"
      v-model="projectDetailsSideBar"
      id="sidebar-edit"
      aria-label="Sidebar with custom footer"
      no-header
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-button size="sm" @click="hide">Fermer</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <b-card>
          <template #header>
            <h2>Projet :</h2>
          </template>
          <validation-observer>
            <b-form>
              <b-row>
                <b-col cols="12" md="12">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Nom du projet'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.name"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Date de début'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.start_date"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Date de fin'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.end_date"
                  />
                </b-col>
                <b-col cols="12" md="12">
                  <validation-provider>
                    <b-form-group>
                      <b-form-textarea readonly v-model="project.description" />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  Responsable :
                  <span v-if="project.master">
                    {{ project.master }}
                    <b-button size="sm" pill @click="changeMasterBool = true"
                      >Changer de responsable</b-button
                    >
                  </span>

                  <multiselect
                    v-if="changeMasterBool == true"
                    class="mt-1"
                    v-model="master_object"
                    placeholder="Veuillez choisir un responsable"
                    :options="filtered_masters"
                    :custom-label="masterName"
                    :value="master_object"
                    selectLabel=""
                    deselectLabel=""
                    :showNoResults="false"
                  >
                    span(slot="noResult"). Oops! No elements found. Consider
                    changing the search query. pre.language-json code.
                  </multiselect>
                </b-col>
                <small
                  v-if="stateData.user_id == null"
                  class="ml-2 text-danger"
                >
                  <strong>NB:</strong> Veuillez choisir un responsable pour ce
                  projet</small
                >
                <!-- <b-col v-if="changeMasterBool == true" class="mt-1 text-right">
                  <b-form-group>
                    <b-button @click="associateMasterToProject()"
                      >Sauvegarder</b-button
                    >
                  </b-form-group>
                </b-col> -->
              </b-row>
              <hr />
              <b-button
                block
                @click="acceptProject(project)"
                :disabled="master_object == null"
                >Accepter le projet
              </b-button>
              <b-button
                block
                variant="danger"
                @click="rejectedProjectModal = true"
                >Rejeté le projet</b-button
              >
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </b-sidebar>
    <b-modal
      v-model="rejectedProjectModal"
      title="Information"
      ok-variant="danger"
      cancel-variant="outline-primary"
      cancel-title="Non, annuler"
      ok-title="Oui, rejeté"
      @ok="rejectProject(project)"
    >
      Voulez-vous vraiment rejeté ce projet ? <br />
      Cette action est irréversible.
      <hr />
    </b-modal>
  </div>
</template>

<script>
import VueContext from "vue-context";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import moment from "moment";
import utils from "@/scripts/utils";
import { mapActions, mapState } from "vuex";
import AllEnterpriseProjects from "./AllEnterpriseProjects.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

import monthSelect from "flatpickr/dist/plugins/monthSelect/index";
export default {
  components: {
    ToastificationContent,
    VueContext,
    flatPickr,
    FeatherIcon,
    Multiselect,
    KyInput,
    vSelect,
    AllEnterpriseProjects,
  },
  data() {
    return {
      rejectedProjectModal: false,
      changeMasterBool: false,
      master_object: null,
      isBusy: false,
      projectDetailsSideBar: false,
      stateData: {
        user_id: null,
        project_id: null,
        state: 0,
      },
      projectsItems: [
        { key: "customer", label: "Client", sortable: true },
        { key: "name", label: "Projet", sortable: true },
        { key: "start_date", label: "Date de début", sortable: true },
        { key: "end_date", label: "Date de fin", sortable: true },
        { key: "master", label: "Responsable", sortable: true },
        // { key: "note", label: "Note", sortable: true },
        { key: "action", label: "Action" },
      ],
      rows: 0,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTable: "",
      backup_text: "Sauvegardé!",
      localBackup: new Map(
        JSON.parse(localStorage.getItem("projects_bag_backup"))
      ),

      fields: [
        {
          key: "id",
          label: "N°",
          thStyle: { width: "1px" },
          /* tdClass: (value, key, item) => {
            if (item.is_finished) return "index-td";
            return "index-td-blue";
          }, */
        },
        {
          key: "is_finished",
          label: "✔",
          thStyle: { width: "1px" },
          formatter: (value) => {
            return parseInt(value) == 1 ? true : false;
          },
        },
        {
          key: "name",
          label: "Projets",
          thClass: "table-th",
          sortable: true,
        },
        {
          key: "masters",
          label: "Responsable",
          thStyle: { width: 0 },
        },
        {
          key: "end_date",
          label: "Date de fin",

          thClass: "end-date-th",
        },

        {
          key: "customer",
          label: "Client",
        },
      ],

      projectBag: [],

      line_through: "text-decoration:line-through",
      selectedIndex: -1,
      selectedProject: null,
      index: -1,

      config: {
        altFormat: "J F Y",
        // locale: French,
        // altInput: true,
        dateFormat: "Y-m-d",
      },

      config2: {
        altFormat: "J F Y",
        locale: French,
        dateFormat: "Y-m-d",

        onChange: function (selectedDates, dateStr, instance) {
          this.tab = 10;
        },
        plugins: [
          new monthSelect({
            shorthand: true,
            dateFormat: "M Y",
            theme: "dark",
          }),
        ],
      },

      filtered_masters: [],
      filtered_projects: [],
      filtered_customers: [],

      master_search: "",
      project_search: "",
      customer_search: "",

      add_comment: false,

      months: ["Janvier", "Février", "Mars"],

      flat: null,

      tab: 0,
      toolbox_tab: 0,
      syncronized: false,
      check_all_master: false,
      activeTabTasks: null,

      popoverShow: false,
      tableId: 0,
      loading: true,
      project: {},
    };
  },

  computed: {
    sortOptions() {
      return this.projectsItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    filteredItems() {
      return (
        this.allCustomersProjects?.filter((item) => {
          return item?.name
            .toLowerCase()
            .includes(this.filterTable.toLowerCase());
        }) || this.allCustomersProjects
      );
    },

    ...mapState("projects", [
      "projectsBag",
      "changeOccured",
      "backuped",
      "allCustomersProjects",
    ]),
    ...mapState("enterprise", ["employees", "clients"]),
    ...mapState("auth", ["userInfo"]),

    masters() {
      return this.employees;
    },

    daysCount() {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1)
        ).daysInMonth();
      } else {
        return 1;
      }
    },

    activeMonth() {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1)
        )
          .locale("fr")
          .format("MMMM");
      }
    },

    activeYear() {
      if (this.flat) {
        return this.flat.currentYear;
      }
    },

    selectedKey() {
      if (this.flat) {
        return (
          this.flat.currentYear +
          "-" +
          (this.flat.currentMonth + 1) +
          "-" +
          (this.tab + 1)
        );
      }
    },
  },

  methods: {
    masterName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },

    onFiltered(items) {
      this.totalRows = items.length;
      this.currentPage = 1;
    },

    acceptProject(project) {
      this.stateData.project_id = project.id;
      this.stateData.state = 1;
      this.accepteCustomerProject(this.$formData(this.stateData)).then(
        (res) => {
          if (this.project.master == null) this.changeMasterBool = true;
          else this.changeMasterBool = false;
          (this.isBusy = true), (this.projectDetailsSideBar = false);
          this.getAllCustomersProjects().then(() => {
            this.isBusy = false;
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Projet accepté avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      );
    },

    rejectProject(project) {
      this.stateData.project_id = project.id;
      this.stateData.state = 2;
      this.accepteCustomerProject(this.$formData(this.stateData)).then(
        (res) => {
          (this.projectDetailsSideBar = false),
            this.getAllCustomersProjects().then(() => {
              this.isBusy = false;
            });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Projet rejeté avec succès",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      );
    },

    getCustomer(id) {
      var val = this.clients.find((item) => item.id == id);

      return val ? val.name : null;
    },
    addCollaborator() {
      this.$router.push({ path: "/admin/users", query: { from: "project" } });
    },
    ...utils,
    ...mapActions("projects", [
      "tabulate",
      "compute",
      "fill",
      "remove",
      "selectAllMaster",
      "selectProject",
      "selectCustomer",
      "setPriority",
      "getProjects",
      "backup",
      "sync",
      "selectMaster",
      "getAllCustomersProjects",
      "accepteCustomerProject",
      "associateMaster",
    ]),
    ...mapActions("enterprise", ["getEmployees", "fetchCustomers"]),

    getEmployee(id) {
      var val = this.employees.find((item) => item.id == id);

      return val ? val.firstname + " " + val.lastname : null;
    },
    isCustomerDetachable() {
      if (!this.selectedProject) return false;
      if (!this.selectedProject.project) return true;
      if (this.selectedProject.project && this.selectedProject.project.customer)
        return false;
    },

    checkedCount(array) {
      return array.filter((item) => item.is_finished == true).length;
    },

    checkedCountTotal(array) {
      var regx = /\w+|-/;
      return array.filter((item) => regx.test(item.name) == true).length;
    },

    doubleClick(item, index) {
      this.selectedIndex = index;
      this[`$refs.sheet${this.tab}.clearSelected()`];
    },

    clickRow(item, index) {
      this.selectProject = item;

      this[`$refs.sheet${this.tab}.selectRow(${index})`];
    },

    openCtx(item, other) {
      this.$refs.menu.open();
    },

    rowContext(item, index) {
      this.selectedProject = item;
      this.selectedIndex = index;
    },

    addLine() {
      const project = {
        is_finished: false,
        id: 1,
        title: "",
        master: "",
      };

      this.fill(this.selectedKey);

      this.forceUpdate();
      /* subtil */

      // this.tab--
    },

    forceUpdate() {
      this.tableId++;
    },

    removeLine() {
      this.remove({ key: this.selectedKey, index: this.selectedIndex })
        .then((r) => {
          this.forceUpdate();
          this.$bvToast.toast("Une donnée supprimée.", {
            variant: "primary",
            solid: true,
            title: "Message",
          });
        })
        .catch((e) => {
          this.$bvToast.toast("Echec de suppression", {
            variant: "danger",
            solid: true,
            title: "Erreur",
          });
        });
    },

    associateMasterToProject() {
      var data = {
        project_id: this.project.id,
        master_id: this.master_object.id,
      };
      this.associateMaster(this.$formData(data)).then((res) => {
        this.getAllCustomersProjects().then(() => {
          this.isBusy = false;
        });
        this.projectDetailsSideBar = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Collaborateur associé avec succès",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    chooseMaster(item, index, master) {
      this.selectMaster({ key: item, index: index, master: master });
    },

    detachCustomer() {
      this.chooseCustomer(this.selectedKey, this.selectedIndex, null);
    },

    chooseCustomer(item, index, customer) {
      this.selectCustomer({ key: item, index: index, customer: customer });
      this.forceUpdate();
    },

    projectFocus(item, index) {
      this.selectedIndex = index;

      this.activeTabTasks = this.projectsBag.get(item);
    },

    localeDay(num) {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1) + "-" + num
        )
          .locale("fr")
          .format("ddd");
      } else {
        return "-";
      }
    },

    goToTask(id) {
      this.$router.push("/projects2");
    },
  },

  watch: {
    master_object(val) {
      this.stateData.user_id = val.id;
    },

    backuped() {
      this.$bvToast.toast("Enregistré!", {
        variant: "primary",
        solid: true,
        title: "Message",
      });
      this.localBackup = new Map(
        JSON.parse(localStorage.getItem("projects_bag_backup"))
      );
    },
    changeOccured() {
      this.forceUpdate();
    },
    master_search(val) {
      this.filtered_masters = this.masters.filter(
        (item) =>
          item.firstname.toLowerCase().includes(val.toLowerCase()) ||
          item.lastname.toLowerCase().includes(val.toLowerCase())
      );
    },

    project_search(val) {
      this.filtered_projects = this.projects.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
    },

    customer_search(val) {
      this.filtered_customers = this.customers.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
    },

    syncronized() {
      this.backup();
      this.loading = true;
      this.sync(this.selectedKey).then((r) => {
        this.loading = false;
        this.$bvToast.toast("Synchronisation éffectué", {
          variant: "primary",
          solid: true,
          title: "Message",
        })
        //
        this.localBackup = new Map(
          JSON.parse(localStorage.getItem("projects_bag_backup"))
        );
      }).catch((err) => {
          this.loading = false;
          this.$bvToast.toast("Synchronisation échouée, veuillez vérifier si la date de fin est bien renseignée", {
          variant: "danger",
          solid: true,
          title: "Erreur",
        })
        });
    },

    activeMonth() {
      this.localBackup = new Map(
        JSON.parse(localStorage.getItem("projects_bag_backup"))
      );

      this.compute(
        (this.flat?.currentYear || new Date().getFullYear()) +
          "-" +
          (this.flat?.currentMonth + 1 || new Date().getMonth())
      ).then((d) => {
        this.getProjects(this.selectedKey).then((r) => {
          this.forceUpdate();
        });
      });
    },

    activeYear() {
      this.compute(
        (this.flat?.currentYear || new Date().getFullYear()) +
          "-" +
          (this.flat?.currentMonth + 1 || new Date().getMonth())
      ).then((d) => {});
    },

    tab() {
      if (this.selectedKey) {
        this.getProjects(this.selectedKey).then((r) => {
          this.forceUpdate();
        });
      }
    },

    selectedProject: {
      deep: true,
      handler(val) {
        // alert(val.name);
      },
    },
  },

  created() {
    if (this.$route.query.q) {
      this.toolbox_tab = 2;
    }
  },

  mounted() {
    this.getAllCustomersProjects().then(() => {
      this.isBusy = false;
    });
    this.compute(
      (this.flat?.currentYear || new Date().getFullYear()) +
        "-" +
        (this.flat?.currentMonth + 1 || new Date().getMonth())
    ).then((d) => {});

    setTimeout(() => {
      this.flat = flatpickr("#flat", this.config2);
      this.tab = Number(moment(new Date()).format("D")) - 1;
      this.flat.changeMonth(new Date().getMonth());
      this.loading = false;
      this.getProjects(this.selectedKey).then((r) => {
        this.forceUpdate();
      });
    }, 1000);

    this.getEmployees().then((r) => {
      this.filtered_masters = this.masters;
    });

    this.fetchCustomers().then((r) => {
      this.filtered_customers = this.clients;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/priority-box.scss";

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}

::v-deep .table-th {
  width: 65% !important;
}

::v-deep .end-date-th {
  width: 35%;
}

.nav-tabs {
  flex-wrap: nowrap;
}

::v-deep .tab-nav-class {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 10%);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  @extend %scrollbar;

  & li.nav-item {
    white-space: nowrap;
  }
}

.b-table-sticky-header {
  max-height: calc(100vh - 235px) !important;
}

.card-body {
  height: calc(100vh - 95px);
  padding: 1.5rem;
  overflow-y: hidden;
  overflow-x: scroll;
}

.popo {
  height: 370px;
  overflow-y: scroll;
}

.popover-div {
  max-height: calc(100vh / 4);
  overflow-y: scroll;
  @extend %scrollbar;
}

.table-responsive {
  @extend %scrollbar;
}

.flat-form-control {
  border: none;
  cursor: pointer;
}

.project-title {
  max-height: 40px;
  overflow-y: auto;
}

.index-td {
  border-left: 10px solid red !important;
}

.index-td-blue {
  border-left: 10px solid $primary !important;
}

.legende-box-primary {
  background: $primary;
  @extend %legende-box;
}

.legende-box-danger {
  background: $danger;
  @extend %legende-box;
}

%legende-box {
  height: 20px;
  width: 20px;

  margin-bottom: 10px;
  position: absolute;
}
</style>