<template>
  <b-card class="ky-card-body">
    <div class="justify-content-between pb-2">
      <!-- filter -->
      <b-row>
        <b-col md="auto">
          <b-button class="" @click="initSearchEntries" size="sm">
            Réinitialiser
          </b-button>
        </b-col>
        <b-col>
          <validation-observer ref="searchForm">
            <b-form>
              <b-form-group class="mb-0 ml-auto">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="search"
                    type="text"
                    placeholder="Rechercher par nom de projet..."
                  />
                </b-input-group>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label for="">Responsables :</label>
              <multiselect
                v-model="collaboratorMultiSelect"
                placeholder="Filtrer par responsable"
                :options="masters"
                :custom-label="userName"
                :value="collaboratorMultiSelect"
                selectLabel=""
                deselectLabel=""
                :showNoResults="false"
              >
                Pas d'element trouvé
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="">Clients :</label>
              <multiselect
                v-model="customerMultiSelect"
                placeholder="Filtrer par client"
                :options="clients"
                :custom-label="customerName"
                :value="customerMultiSelect"
                selectLabel=""
                deselectLabel=""
                :showNoResults="false"
              >
                Pas d'element trouvé
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Date de début du projet :</label>
              <b-input v-model="filterData.startDate" type="date"></b-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Date de fin du projet :</label>
              <b-input v-model="filterData.endDate" type="date"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row> </b-row>
      </b-form>
    </div>

    <b-table
      :busy="isBusy"
      sticky-header
      :per-page="perPage"
      :current-page="currentPage"
      hover
      :key="allEnterpriseProjectsFilter"
      selected-variant="primary"
      bordered
      head-variant="dark"
      striped
      empty-text="Aucun projet trouvé"
      show-empty
      responsive
      class="position-relative"
      :fields="fields"
      :items="allprojects"
      @row-clicked="projectDetails"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Veuillez patienter...</strong>
        </div>
      </template>
      <template #cell(customer)="data">
        <span> {{ data.item.customer }} </span>
      </template>
      <template #cell(is_finished)="data">
        <b-checkbox
          disabled
          :checked="data.item.is_finished == 1 ? 'true' : 'false'"
        />
      </template>
    </b-table>
    <b-sidebar
      no-close-on-backdrop
      no-header-close
      v-model="showProjectDetails"
      id="allProjectTasks-sidebar"
      style="width: 10px"
      backdrop
      shadow
      bg-variant="white"
      right
      width="80%"
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-1 py-1">
          <strong class="mr-auto"></strong>
          <b-button size="sm" @click="hide">Fermer</b-button>
        </div>
      </template>
      <div class="p-1">
        <div class="d-flex justify-content-between">
          <h3>Projet du client : {{ project.customer }}</h3>
          <b-button
            v-if="readOnly == true"
            @click="readOnly = false"
            size="sm"
            variant="outline-primary"
          >
            <feather-icon icon="EditIcon" /> Éditer</b-button
          >
          <b-button
            :disabled="projectUpdating"
            v-if="readOnly == false"
            @click="updateThisProject(project)"
            size="sm"
            ><b-spinner v-if="projectUpdating" type="grow" small></b-spinner>
            <feather-icon icon="EditIcon" />
            Sauvegarder</b-button
          >
        </div>
        <div>
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="Projet :">
                  <b-form-input :readonly="readOnly" v-model="project.name" />
                </b-form-group>
                <b-form-group label="Responsable actuel :">
                  <b-form-input :readonly="readOnly" v-model="project.master" />
                </b-form-group>
                <b-form-group
                  v-if="readOnly == false"
                  label="Nouveau responsable :"
                >
                  <multiselect
                    v-model="masterMultiSelect"
                    placeholder="Filtrer par responsable"
                    :options="masters"
                    :custom-label="userName"
                    :value="masterMultiSelect"
                    selectLabel=""
                    deselectLabel=""
                    :showNoResults="false"
                  >
                    Pas d'element trouvé
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Date de debut :">
                  <b-form-input
                    :readonly="readOnly"
                    v-model="project.start_date"
                    type="date"
                  />
                </b-form-group>
                <b-form-group label="Date de fin :">
                  <b-form-input
                    :readonly="readOnly"
                    v-model="project.end_date"
                    type="date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                :class="
                  project.is_finished == 0
                    ? 'alert alert-warning p-1'
                    : 'alert alert-success p-1'
                "
              >
                <small v-if="project.is_finished == 0" class=""
                  >Ce projet est en cours d'éxecution.</small
                >
                <small v-if="project.is_finished == 1"
                  >Ce projet est achevé.</small
                >
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="">
          <b-form-group
            label="Recherche"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-1"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="..."
              />
            </b-input-group>
          </b-form-group>
          <div>
            <b-table
              :busy="customerProjectLoading"
              empty-filtered-text="Aucune tâche pour cette recherche"
              empty-text="Aucune tâche pour ce client"
              show-empty
              responsive
              head-variant="dark"
              striped
              :fields="fields"
              :items="allprojecttasks"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Veuillez patienter...</strong>
                </div>
              </template>
              <template #cell(is_finished)="data" class="task-respo">
                <b-form-checkbox
                  button-variant="primary"
                  :disabled="
                    (data.item.supervisor_id == userInfo.id &&
                      data.item.is_finished == 0) ||
                    (data.item.user_id != userInfo.id &&
                      data.item.supervisor_id != userInfo.id) ||
                    (data.item.user_id == userInfo.id &&
                      data.item.is_closed == 1)
                  "
                  v-model="data.item[getKey(data.item)]"
                  :indeterminate="
                    ((!data.item.is_closed && data.item.is_finished) ||
                      (data.item.supervisor_id && data.item.is_finished)) == 1
                      ? true
                      : false
                  "
                  class="custom-control-primary"
                  :value="1"
                  @change="_taskChecked(data.item)"
                >
                </b-form-checkbox>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-sidebar>
    <!-- <div v-if="alltasks <= 0"  class="alert alert-info">
        <p> hello </p>
    </div> -->
    <div class="">
      <!-- pagination -->
      <b-row>
        <b-col>
          <b-form-group class="">
            <label class="d-inline-block text-sm-left mr-50">Affichage</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col md="8" class="d-flex flex-row-reverse">
          <div class="">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTooltip,
  BIconFileEasel,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    vSelect,
    BTooltip,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ToastificationContent,
  },
  data() {
    return {
      customerProjectLoading: false,
      projectUpdating: false,
      readOnly: true,
      project: {},
      isBusy: false,
      search: "",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,

      showProjectDetails: false,
      exportIsProgress: false,
      showMoreVertical: false,
      currentPage: 1,
      collaboratorMultiSelect: null,
      customerMultiSelect: null,
      masterMultiSelect: null,

      errors: "",
      task: {},
      thisTableSize: 10,
      filterData: {
        collaboratorId: null,
        customerId: null,
        startDate: "",
        endDate: "",
      },
      allEnterpriseProjectsFilter: 0,
      loading: false,
      search: "",

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "is_finished",
          label: "✔",
          thStyle: { width: "1rem" },
          sortable: true,
        },

        { key: "name", label: "Projet", sortable: true },

        {
          key: "start_date_format",
          label: "Date debut",
          sortable: true,
        },
        {
          key: "end_date_format",
          label: "Date Fin",
          sortable: true,
        },
        {
          key: "master",
          label: "Responsable",
          thStyle: { width: "2rem" },
          sortable: true,
        },

        { key: "customer", label: "Projet Client", sortable: true },
      ],
    };
  },
  watch: {
    search(val) {
      this.isBusy = true;
      this.filterByName(val).then((e) => {
        this.isBusy = false;
      });
    },
    allprojects(val) {
      this.totalRows = val.length;
    },

    filterData: {
      deep: true,
      handler() {
        this.isBusy = true;
        return this.projectGlobalFilter(this.filterData).then((e) => {
          this.isBusy = false;
        });
      },
    },

    collaboratorMultiSelect(val) {
      if (val) {
        this.filterData.collaboratorId = val.id;
      } else {
        this.filterData.collaboratorId = null;
      }
    },
    customerMultiSelect(val) {
      if (val) {
        this.filterData.customerId = val.id;
      } else {
        this.filterData.customerId = null;
      }
    },

    masterMultiSelect(val) {
      this.project.user_id = val.id;
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    masters() {
      return this.employees;
    },
    ...mapState("enterprise", ["employees", "supervisors", "clients"]),
    ...mapState("projects", ["allprojects", "allprojecttasks"]),
    ...mapState("auth", ["userInfo"]),
  },

  mounted() {
    this.fetchCustomers();
    this.getEmployees();
    this.mountedProjects();
    this.totalRows = this.allprojects.length;
  },
  methods: {
    ...mapActions("enterprise", ["getEmployees", "fetchCustomers"]),
    ...mapActions("projects", [
      "allEnterpriseProjects",
      "projectGlobalFilter",
      "filterByName",
      "projectTasks",
      "editProject",
    ]),
    ...mapActions("tasks", ["closedTask", "finishedTask", "taskChecked"]),

    mountedProjects() {
      this.isBusy = true;
      this.projectGlobalFilter(this.filterData).then((e) => {
        this.isBusy = false;
      });
    },
    getKey(task) {
      if (task.supervisor_id == this.userInfo.id) {
        return "is_closed";
      } else if (task.supervisor_id == null) {
        return "is_closed";
      } else if (task.user_id == this.userInfo.id) {
        return "is_finished";
      }
    },

    _taskChecked(task) {
      this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    updateThisProject(data) {
      this.projectUpdating = true;
      this.readOnly = false;
      if (data.start_date == null) {
        this.projectUpdating = false;
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: "Veuillez rensigner la date de début du projet",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      this.editProject(this.$formData(data))
        .then((e) => {
          this.projectUpdating = false;
          this.projectGlobalFilter(this.filterData);
          this.project.master =
            this.masterMultiSelect.firstname +
            " " +
            this.masterMultiSelect.lastname;
          this.readOnly = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Projet modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.projectUpdating = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    showProjectTasks(data) {
      this.projectTasks(data.id);
    },

    initSearchEntries() {
      this.customerMultiSelect = null;
      this.collaboratorMultiSelect = null;
      this.filterData.collaboratorId = null;
      this.filterData.customerId = null;
      this.filterData.startDate = "";
      this.filterData.endDate = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    customerName(customer) {
      return customer.name;
    },

    userName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },

    customerName({ name }) {
      return name;
    },

    projectDetails(data) {
      this.customerProjectLoading = true;
      this.projectTasks(data.id).then(() => {
        this.customerProjectLoading = false;
      });
      this.showProjectDetails = true;
      this.project = data;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.b-table-sticky-header {
  max-height: calc(100vh - 235px) !important;
}
</style>




